<template>
  <v-app dark style="background-color: #efefef">
    <div>
      <v-img max-height="70" min-height="55" :src="headerPath"> </v-img>
      <v-toolbar dense flat>
        <v-card
          color="transparent"
          flat
          style="margin-top: -70px; border-radius: 0px"
        >
          <img
            :src="logoPath"
            width="170px"
          />
        </v-card>
        <v-toolbar-title class="ml-4 hidden-sm-and-down"
          ><h4>{{ $store.state.pageTitle }}</h4></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="openLogin"
          ><v-icon left>mdi-login</v-icon> Login</v-btn
        >
      </v-toolbar>
    </div>
    <v-main>
      <div class="mb-10">
        <router-view></router-view>
      </div>
    </v-main>
    <v-footer dark class="pt-1 pb-1">
      <v-card width="100%">
        <v-card-actions class="pa-1" style="font-size: 0.8rem">
          <span>&copy; {{ new Date().getFullYear() }}</span>
          <v-spacer></v-spacer>
          Powered By
          <img src="/DNA-Logo-mono.png" height="45px" class="pl-3" alt="" />
        </v-card-actions>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  props: { EventUrl: String, VenueUrl: String },
  data() {
    return {
      logoPath: "",
      headerPath: "",
      clipped: false,
      drawer: true,
      fixed: false,
      cpDialog: false,
    };
  },
  async created() {
    this.logoPath =
      this.$apiPhotoPath + "/assets/" + this.EventUrl + "/logo.jpg";

    this.headerPath =
      this.$apiPhotoPath + "/assets/" + this.EventUrl + "/header.jpg";

    await this.$store.commit("setEventVenueUrl", {
      e: this.EventUrl,
      v: this.VenueUrl,
    });
  },
  methods: {
    openNewApplication: function () {
      this.$store.commit("resetApplication");
      this.$router.push("/newapplication").catch(() => {});
    },
    openReports: function () {
      this.$router.push("/reports").catch(() => {});
    },
    openHome: function () {
      this.$router.push("/dashboard").catch(() => {});
    },
    openLogin() {
      this.$store.commit("logout");
      this.$router
        .push(
          "/" +
            this.$store.state.eventUrl +
            "/" +
            this.$store.state.venueUrl +
            "/login"
        )
        .catch(() => {});
    },
    logout: function () {
      this.$store.commit("logout");
      this.$router
        .push(
          "/" +
            this.$store.state.eventUrl +
            "/" +
            this.$store.state.venueUrl +
            "/login"
        )
        .catch(() => {});
    },
  },
};
</script>
<style>
/* body::before {
    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: linear-gradient(#f48d95, #fed387);
} */
.cardHeader {
  background-color: rgb(226, 240, 252);
  padding: 8px;
  padding-left: 10px;
  border-left: 10px solid rgb(75, 35, 250);
  font-weight: 500;
  font-size: 1.05rem;
}
</style>